import { z } from 'zod';
import { fetch as tauriFetch } from '@tauri-apps/plugin-http';
import { isTauri } from '@tauri-apps/api/core';
import {
    timestampInSeconds,
    addBreadcrumb,
    getBreadcrumbLogLevelFromHttpStatusCode
} from '@sentry/core';

export const UserTokenId = z.string().startsWith('utokn_').brand<'UserTokenId'>();
export type UserTokenId = z.infer<typeof UserTokenId>;
export const UserId = z.string().startsWith('user_').brand<'UserId'>();
export type UserId = z.infer<typeof UserId>;

export const UserToken = z.object({
    id: z.string().startsWith('utokn_').brand<'UserTokenId'>(),
    prefix: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    userId: UserId,
    description: z.string().optional(),
    purpose: z.enum(['speak', 'stream', 'moderate', 'unknown'])
});
export type UserToken = z.infer<typeof UserToken>;

export function serverUrl(path: URL | string): URL {
    if (isTauri()) {
        if (import.meta.env.DEV) {
            return new URL(path, `http://localhost:8080/`);
        } else {
            return new URL(path, `https://beta.outloud.chat/`);
        }
    } else {
        return new URL(path, window.location.href);
    }
}

export function setSessionToken(sessionToken: string) {
    localStorage.setItem('sessionToken', sessionToken);
}

export function getSessionToken(): string | null {
    return localStorage.getItem('sessionToken');
}

function performTauriFetch(url: URL, options: RequestInit = {}): Promise<Response> {
    const method = (options.method ?? 'GET').toUpperCase();
    const fetchData = {
        method,
        url
    };

    const hint = {
        input: [url, options],
        response: undefined,
        startTimestamp: timestampInSeconds() * 1000
    };

    addBreadcrumb(
        {
            category: 'fetch',
            data: fetchData,
            type: 'http',
            level: getBreadcrumbLogLevelFromHttpStatusCode(undefined)
        },
        hint
    );

    return tauriFetch(url.toString(), options).then(
        (response) => {
            addBreadcrumb(
                {
                    category: 'fetch',
                    data: { ...fetchData, status_code: response.status },
                    type: 'http',
                    level: getBreadcrumbLogLevelFromHttpStatusCode(response.status)
                },
                { ...hint, response, endTimestamp: timestampInSeconds() * 1000 }
            );

            return response;
        },
        (error) => {
            addBreadcrumb(
                {
                    category: 'fetch',
                    data: fetchData,
                    type: 'http',
                    level: 'error'
                },
                { ...hint, data: error, endTimestamp: timestampInSeconds() * 1000 }
            );

            throw error;
        }
    );
}

export async function serverFetch(url: URL | string, options: RequestInit = {}): Promise<Response> {
    const acutalUrl = serverUrl(url);
    const _fetch = isTauri() ? performTauriFetch : fetch;
    const sessionToken = getSessionToken();
    if (sessionToken) {
        options.headers = addHeaders(
            options.headers ?? [],
            'Cookie',
            `outloud.auth=${sessionToken}`
        );
    }
    const response = await _fetch(acutalUrl, options);
    if (response.status === 401) {
        localStorage.removeItem('sessionToken');
        window.location.reload();
    }
    return response;
}

function addHeaders(headers: HeadersInit, key: string, value: string): HeadersInit {
    const newHeaders = new Headers(headers);
    newHeaders.set(key, value);
    return newHeaders;
}
